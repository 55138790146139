
/*RESET*/
*, *:before, *:after{
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  font-size: 16px;
}


html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, strong, sub, sup, tt, b, u, i, ol, ul, li, fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video{
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-size-adjust: none;
}

footer, header, nav, section, main{
  display: block;
}

ol, ul{
  list-style: none;
}

blockquote, q{
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after{
  content: '';
  content: none;
}

table{
  border-collapse: collapse;
  border-spacing: 0;
}

input{
  -webkit-appearance: none;
  border-radius: 0;
}

body {
  background-size: 100%;
  line-height: 1.3;
  cursor: default;
}

/*
========================================
Typography
========================================
*/


b, strong {
  font-weight: bold;
}

em,
i {
font-style:italic;
}

/*
========================================
Img
========================================
*/

img,
picture,
video,
embed,
object {
  max-width: 100%;
}

img {
  display: inline-block;
  vertical-align: middle;
}

/**/
body {
   background:#ffffff;
   font-family: 'Poppins', sans-serif;
   color:#000000;
   font-weight:500;
}

p {
   font-size: 1rem;
   font-family: 'Poppins', sans-serif;
   color:#fff;
   line-height: 2;
   font-weight: 500;
   letter-spacing: 0.3px;
   margin-bottom: 20px;
}


/*
========================================
GERAL
========================================
*/

.display100BetCen {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/*COR STATUS*/

.status-1 {background: #c6f9bd;}
.status-2 {background: #f9f2c4;}
.status-3 {background: #ffcfe3;}
.status-4 {background: #ffaeae;}
.status-5 {background: #d2d2d2;}
.status-6 {background: #f8f8f8;}