
.title {
    padding: 25px 0;
  }
  
  .title h1 {
    font-size: 25px;
    color: #969696;
    font-weight: 600;
    margin-top: 0px;
  }
  