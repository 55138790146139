.input {
    width: 255px;
}

.input select {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    width: 100%;
    padding: 6px 25px 6px 10px;
    background: #ffffff;
    border: 1px solid #1f284b;
    outline: none;
    font-size: 15px;
    font-family: 'Lato', sans-serif;
    color: #1f284b;
    position: relative;
    z-index: 1;
    cursor: pointer;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.input input,
.input textarea,
.input input[type="text"] {
    width: 100%;
    padding: 5.5px 25px 5.5px 8px;
    background:#ffffff;
    border: 1px solid #1f284b;
    outline: none;
    font-size: 15px;
    font-family: 'Lato', sans-serif;
    color: #1f284b;
    position: relative;
    z-index: 1;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.input textarea {
    height: 150px;
}

.input :-ms-input-placeholder {
    color: #1f284b;
}

.input label {
    display: block;
    font-size: 16.5px;
    color: #6ab76a;
    font-weight: 600;
    margin-bottom: 5px;
}

.input input[type=submit] {
    width: 100%;
    background: #1f284b;
    border: 1px solid #dfdfdf;
    color: #6fc06c;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    padding: 10px 0;
    margin-top: 8px;
    outline: none;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 20px;
    text-transform: uppercase;
}