.navbar {
    background: #1f284b;
    padding: 20px;
}

.list {
    margin-left: 20px;
    display:flex;
    background: #ebebeb;
    justify-content: flex-start;
    text-align: center;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.list li a {
    display: flex;
    align-items: center;
    padding: 15px 16px;
    font-size: 15px;
    letter-spacing: 1px;
    color: #1f284b;
    text-align: center;
    transition: all 0.5s ease;
    text-decoration: none;
    position: relative;
}

.list li a::after {
    content: '';
    width:1px;
    height: 25px;
    background:#1f284b;
    position: absolute;
    right: 0;
}

.list li a:first-child {
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.list li:last-child a::after {
    display: none;
}

.list li.ativo a,
.list li a:hover {
    color: #1f284b;
    background: #6fc06c;
}

