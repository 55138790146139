.box_filter {
    border: 2px solid #5a5a5a;
    background: #dfdfdf;
    padding: 20px;
    border-radius: 5px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px 38px;
  }